<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Page <span>Edit Coach</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="coach-setting-form" ref="coach-setting-form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Coach Section</h5>
                            <label for="show_content_headline_v2" class="switch_option capsule_btn border-0">
                                <input type="checkbox" id="show_content_headline_v2" :true-value="1" :false-value="0" v-model="form.display_coach" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-show="form.display_coach == 1">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Coach Headline</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.coach_headline }">
                                        <Field autocomplete="off" name="coach_headline" type="text" placeholder="ex: Coach Headline" v-model="form.coach_headline" />
                                    </div>
                                    <ErrorMessage name="coach_headline" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Coach Name</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.coach_name }">
                                        <Field autocomplete="off" name="coach_name" type="text" placeholder="Joe Trainer " v-model="form.coach_name" />
                                    </div>
                                    <ErrorMessage name="coach_name" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Coach Title</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.coach_title }">
                                        <Field autocomplete="off" name="coach_title" type="text" placeholder="PHD, CSCS" v-model="form.coach_title" />
                                    </div>
                                    <ErrorMessage name="coach_title" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <coach-bio-component v-model="form.coach_bio" :default-value="defaultCoachBio" :reset-to-default-watcher="resetToDefaultWatcher" :errors="errors" />
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Coach Image</label>
                                    <image-library v-model="form.coach_image" image-type="coach-logo" :is-avatar="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="pageUpdateLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="pageUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="pageUpdateLoader"></i> {{ pageUpdateLoader ? 'Updating' : 'Update' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const Redactor = defineAsyncComponent(() => import('@/components/Redactor'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const CoachBioComponent = defineAsyncComponent(() => import('@/components/CoachBioComponent'))

    export default {
        name: 'Page Offer Headline',

        data () {
            return {
                form: {
                  display_coach: 0,
                  coach_headline: '',
                  coach_name: '',
                  coach_title: '',
                  coach_bio: '',
                  coach_image: '',
                },
                defaultCoachBio: '',
                resetToDefaultWatcher: 0,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            // Redactor,
            ImageLibrary,
            CoachBioComponent,
        },

        watch: {
            selectedPage () {
                const vm = this;

                vm.resetForm();
            },

            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                }
            },
        },

        computed: mapState({
            selectedPage: state => state.pageModule.selectedPage,
            pageUpdateLoader: state => state.pageModule.pageUpdateLoader,
        }),

        methods: {
            ...mapActions({
                updatePage: 'pageModule/updatePage',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                setTimeout(function () {
                    vm.form = {
                        page_id: vm.selectedPage.id,
                        display_coach: vm.selectedPage.display_coach ? vm.selectedPage.display_coach : 0,
                        coach_headline: vm.selectedPage.coach_headline ? vm.selectedPage.coach_headline : '',
                        coach_name: vm.selectedPage.coach_name ? vm.selectedPage.coach_name : '',
                        coach_title: vm.selectedPage.coach_title ? vm.selectedPage.coach_title : '',
                        coach_bio: vm.selectedPage.coach_bio ? vm.selectedPage.coach_bio : '',
                        coach_image: vm.selectedPage.coach_image ? vm.selectedPage.coach_image : '',
                    };
                }, 10);

                vm.defaultCoachBio = vm.selectedPage.coach_bio ? vm.selectedPage.coach_bio : '',

                setTimeout(function () {
                    vm.resetToDefaultWatcher += 1;
                }, 1000);
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError  = setFieldError;

                vm.updatePage(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style>
    .coach-setting-form {
        height: calc(100vh - 180px);
    }
</style>
